import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search';
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from 'react-helmet';
import { StaticImage } from 'gatsby-plugin-image';

import styled from 'styled-components';

const MainWrapper = styled.div`margin: 0;`;

const faqMeta1 = {
	'@context': 'https://schema.org',
	'@type': 'FAQPage',
	mainEntity: {
		'@type': 'Question',
		name: 'What Does Software Engineer Do?',
		acceptedAnswer: {
			'@type': 'Answer',
			text:
				'Software engineers are computer professionals with knowledge of programming languages and engineering principles to build software products, create computer games, develop business applications and run network control systems.'
		}
	}
};

const faqMeta2 = {
	'@context': 'https://schema.org',
	'@type': 'FAQPage',
	mainEntity: {
		'@type': 'Question',
		name: 'Why Should I Hire A Software Engineer?',
		acceptedAnswer: {
			'@type': 'Answer',
			text:
				'When you hire Codestaff software engineers, you are provided a software engineer’s experience and judgment alongside a solution specifically designed and optimized to solve your problems. You will get professional testing, debugging and QA. When your software breaks, a software engineer helps you fix it.'
		}
	}
};

const SoftwareEngineer = () => (
	<MainWrapper>
		<Helmet>
			<script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
			<script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
		</Helmet>
		<main>
			<section className="cx_catagories">
				<div className="container-fluid cx_top">
					<div className="container">
						<div className="row">
							<div className="col-md-8 col-sm-10">
								<h2>
									Hire top freelance<br />
									<span> Software Engineers.</span>
								</h2>
								<p>
									Codestaff is a marketplace for top Software Engineers. Top companies and start-ups
									choose Codestaff professional Software Engineers for their mission critical software
									projects.
								</p>
								<Link to="/hire/" className="skills-banner-btn">
									Hire a top Software Engineer now
									<StaticImage
										src="../../images/arrow.png"
										alt="arrow"
										className="img-fluid"
										style={{ margin: '0 1rem' }}
									/>
								</Link>
							</div>
						</div>
					</div>
				</div>
				<CxSearch
					title="HIRE FREELANCE SOFTWARE ENGINEERS"
					banner="/software-engineer.png"
					bannerAlt="software engineer banner"
				/>
			</section>
			<HireAndApply />
			<TopTrendingSkills />
		</main>
	</MainWrapper>
);

export default SoftwareEngineer;
